import { signal } from "@preact/signals";
import { Ref, useEffect, useRef } from "preact/hooks";
import { randRange, range, remap } from "../utils/extra.ts";

const bgEffectRef = signal<Ref<HTMLDivElement>|undefined>(undefined)
const dTime = signal(0)

// falling things
const fallNumber = 24

interface FallObjProps {
  iniX: number // % or vw
  delT: number // s
}

const FallObj = (p: FallObjProps) =>
  <div class="fall"
    style={`--ini-x:${p.iniX}%;--del-t:${p.delT}s`}>
    🌿
  </div>
// /falling things

export const BgEffect = () => {
  const _ref = useRef() as Ref<HTMLDivElement> | undefined
  useEffect(() => {
    bgEffectRef.value = _ref

  },[])

  const mapX = remap(0, fallNumber)(0,100)
  const mapT = remap(0, fallNumber)(0,6)

  return <div ref={_ref} id="bg-fx">
    {range(fallNumber).map(i => <FallObj iniX={randRange(0,100)} delT={randRange(0,12 + 1)} />)}
  </div>
}