
/** `inject([1,2,3], 0)` -> `[1,0,2,0,3]` */
export const inject = <T>(array: T[], item: T): T[] =>
  array.reduce((acc: T[], currentValue: T, index: number) => {
    if (index !== 0) {
      acc.push(item);
    }
    acc.push(currentValue);
    return acc;
  }, [])

export const range = (n:number): number[] =>
  Array.from({ length: n }, (_, index) => index)

export const randRange = (min: number, max: number) =>
  min + Math.random() * (max - min)

export const randIn = (min: number, max: number) =>
  randRange(min, max)

export const remap = (fromMin:number, fromMax:number) =>
  (toMin:number, toMax:number) =>
    (val: number) => {

  // Normalize the input value to the original range
  const normalizedValue = (val - fromMin) / (fromMax - fromMin)
  // Map the normalized value to the new range
  const remappedValue = normalizedValue * (toMax - toMin) + toMin

  return remappedValue
}